import { template as template_e94f71fd938a4244b246baa0923fccf5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e94f71fd938a4244b246baa0923fccf5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
