import { template as template_2f9c7c6bf138430990f9f3aa47daa107 } from "@ember/template-compiler";
const FKText = template_2f9c7c6bf138430990f9f3aa47daa107(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
