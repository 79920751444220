import { template as template_d87ca78bb4754d1c97564f9423fb0acc } from "@ember/template-compiler";
const FKLabel = template_d87ca78bb4754d1c97564f9423fb0acc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
