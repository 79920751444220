import { template as template_ac5941a546364bb296b888fd0b5bd131 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_ac5941a546364bb296b888fd0b5bd131(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
